// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap')
window.jQuery = $;
window.$ = $;
window.Rails = Rails;

$(document).ready(function() {
  $('#territory-popup-modal .submit-btn').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    var territory = $(this).siblings().find(".current-territory").val();
    var language = $(this).siblings().find(".current-language").val();

    if (territory === "undefined" || territory === "") {
      territory = $(this).siblings().find(".current-territory").attr("value");
    }
    if (language === "undefined" || language === "") {
      language = $(this).siblings().find(".current-language").attr("value");
    }

    var params = new URLSearchParams(window.location.search);
    params.set('territory', territory);
    params.set('language', language);
    var allParams = params.toString();
    window.location.href = window.location.href.replace( /[\?#].*|$/, "?" + allParams);
  });

  $('.territory-popup').on('click', function() {
    $('#territory-popup-modal').modal('show');
  });

  $('.change_language').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    var params = new URLSearchParams(window.location.search);
    params.set('language', $(this).data().language.replace('#', ''));
    var allParams = params.toString();
    window.location.href = window.location.href.replace( /[\?#].*|$/, "?" + allParams);
  });

  $('.dashb-statistics').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    var is_mobile_view = $('#upcoming-bookings-mob').is(":visible");
    var stats_url = $('.dashb-statistics')[0].href;
    if(is_mobile_view){
      stats_url = stats_url.replace('-desk', '');
    }
    window.location.href = stats_url;
  });

  if($('.percent').length > 0) {
    $('.percent').percentageLoader({
      valElement: 'p',
      strokeWidth: 10,
      bgColor: '#D3D3D3',
      ringColor: '#01bca1',
      textColor: '#000',
      fontSize: '18px',
      fontWeight: 'bold'
    });
  }

  $('body').tooltip({
    selector: "[data-toggle = 'tooltip']"
  });

  $(".party__invites-content .nav-tabs a[data-toggle='tab']").on('click', function(e) {
    $(this).parents("li[role='presentation']").siblings("li").removeClass("active");
    $(this).parents("li[role='presentation']").addClass("active");
  });

  var elem = $('.main-wrapper');
  if(elem.length > 0 && elem.data('popup') == true) {
    $('#campaign-msg').modal('show');
  }

  // Newsletter JS begin
  $('form.newsletter').on('submit', function() {
    $.ajax({
      type: "POST",
      url: '/newsletter_signups',
      data: { email: $(this).find('.newsletter_email:visible:first').val(), name: $(this).find('.newsletter_name').val() },
    });
  });

  $('.newsletter-signup .close_icon').on('click', function(e){
    e.preventDefault();
    $('.newsletter-signup').hide();
  });

  $('.timeplan-menu').on('click', function(){
    if (window.matchMedia('(min-width: 1024px)').matches) {
      window.location.href = $(this).attr('href');
    }
  });

  // Newsletter JS end

  $(".card-expiry input").on('focus', function(){
    $(this).parent().addClass('focus');
  }).on('blur', function(){
    $(this).parent().removeClass('focus');
  });

  // Script for Timer
  if($('.countdown').length > 0) {
    var countDownDate = new Date($('.countdown').first().data().date.replace(/-/g, "/")).getTime();
    var minDigits = $('.countdown').data('min-digits') || 2;

    var x = setInterval(function() {
      var now = new Date().getTime();
      var endDate = countDownDate - now;
      var days = Math.floor(endDate / (1000 * 60 * 60 * 24));
      var hours = Math.floor((endDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((endDate % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((endDate % (1000 * 60)) / 1000);

      var days = (days.toLocaleString(undefined,{minimumIntegerDigits: minDigits}));
      var hours = (hours.toLocaleString(undefined,{minimumIntegerDigits: minDigits}));
      var minutes = (minutes.toLocaleString(undefined,{minimumIntegerDigits: minDigits}));
      var seconds = (seconds.toLocaleString(undefined,{minimumIntegerDigits: minDigits}));

      var counterFor = $('.countdown').data('counter');
      if(counterFor === "class") {
        document.getElementById("timer").innerHTML = hours + ":" + minutes + ":" + seconds;
      } else if(counterFor === "plans") {
        document.getElementById("days").innerHTML = days;
        document.getElementById("hours").innerHTML = hours;
        document.getElementById("minutes").innerHTML = minutes;
        document.getElementById("seconds").innerHTML = seconds;
      }
      else {
        document.getElementById("days").innerHTML = days;
        document.getElementById("hours").innerHTML = hours;
        document.getElementById("minutes").innerHTML = minutes;
        document.getElementById("seconds").innerHTML = seconds;
        // document.getElementById("timer").innerHTML = days + " days " + hours + " hours "
        // + minutes + " mins " + seconds + " secs";
      }

      if (endDate < 0) {
        clearInterval(x);
        if(counterFor === "class") {
          document.getElementById("timer").innerHTML = "00:00:00";
        } else {
          document.getElementById("timer").innerHTML = "EXPIRED";
        }
      }
    }, 1000);
  }

  $('#login-header-form-link').on('click', function(){
    $('#login-header-form').toggle();
  });

  $('#gymme-modal').on('hidden.bs.modal', function () {
    location.href = '/'
  });

  $('#history_tab').on('click', function() {
    if($('.history-title').length < 1) {
      $('.history-content .loader').show();
      $.ajax({
        url: 'dashboard/get_past_klasses'
      });
    }
  });

  $('.eye-icon').on('click', function() {
    if($('#pwd').attr('type') === undefined) {
      $('#pwd').attr('type', 'password')
    } else {
      $('#pwd').removeAttr('type')
    }
  });

  if($('#guest-membership').length > 0) {
    $('#guest-membership').modal('show');
  }

  if($('.webshop_navbar').length > 0) {
    $('.webshop_navbar').on('click', function(event) {
      if ($(window).width() > 990) {
        location.href = event.currentTarget.href
      }
    })
  }
});
